import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import StateContent from '../../../components/content-partials/StateContent';
export const _frontmatter = {
  "unique_id": "NM",
  "title": "New Mexico",
  "includeToc": false,
  "description": "Much of the data on this site is limited to natural resource extraction on federal land, which represents 31.7% of all land in New Mexico.",
  "tags": ["gas", "geothermal", "minerals", "oil", "New Mexico"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <StateContent pageContext={props.pageContext} mdxType="StateContent" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      